export var LoadingState;

(function (LoadingState) {
  LoadingState["UNLOADED"] = "unloaded";
  LoadingState["LOADING"] = "loading";
  LoadingState["LOADED"] = "loaded";
  LoadingState["FAILED"] = "failed";
})(LoadingState || (LoadingState = {}));

export var AjaxContentErrors;

(function (AjaxContentErrors) {
  AjaxContentErrors["FETCH_ERROR"] = "Fetch request failed";
  AjaxContentErrors["REHYDRATE_ERROR"] = "Rehydration failed";
})(AjaxContentErrors || (AjaxContentErrors = {}));