// eslint-disable-next-line
// TODO: find a way to use either Kind or Tag, or a more elegant way to denote one another
export var AnchorElementKind;

(function (AnchorElementKind) {
  AnchorElementKind["LINK"] = "link";
  AnchorElementKind["BUTTON"] = "button";
  AnchorElementKind["SUBMIT"] = "submit";
})(AnchorElementKind || (AnchorElementKind = {}));

export var AnchorElementTags;

(function (AnchorElementTags) {
  AnchorElementTags["DIV"] = "div";
  AnchorElementTags["SPAN"] = "span";
  AnchorElementTags["BUTTON"] = "button";
  AnchorElementTags["LINK"] = "a";
})(AnchorElementTags || (AnchorElementTags = {}));