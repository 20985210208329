import _construct from "@babel/runtime/helpers/esm/construct";
import ConsoleAnalytics from "./ConsoleAnalytics";
import GtmAnalytics from "./GtmAnalytics";
import StorybookAnalytics from "./StorybookAnalytics";
import NullAnalytics from "./NullAnalytics";

var getAnalytics = function getAnalytics(engineName) {
  var engine = null;

  for (var _len = arguments.length, extra = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    extra[_key - 1] = arguments[_key];
  }

  switch (engineName) {
    case "storybook":
      engine = _construct(StorybookAnalytics, extra);
      break;

    case "gtm":
      engine = _construct(GtmAnalytics, extra);
      break;

    case "console":
      engine = _construct(ConsoleAnalytics, extra);
      break;

    case "null":
      engine = _construct(NullAnalytics, extra);
      break;

    default:
      throw new Error("Unsupported analytics engine type: ".concat(engineName));
  } // Enforcing an interface of sorts.


  return function (category, action, label) {
    engine.sendEvent(category, action, label);
  };
};

export default getAnalytics;