import PropTypes from "prop-types";

let siteProps = {};

const updateSiteProps = newProps => {
  siteProps = {
    ...siteProps,
    ...newProps
  };
};

const SiteProps = ({ newProps }) => {
  updateSiteProps(newProps);

  return null;
};

SiteProps.propTypes = {
  /*
   * Object to spread onto the current siteProps.
   */
  newProps: PropTypes.object.isRequired
};

if (typeof document !== "undefined") {
  const sitePropsElement = document.getElementById("siteProps");

  if (sitePropsElement) {
    updateSiteProps(JSON.parse(sitePropsElement.innerHTML));
  }
}

export default {
  get current() {
    return siteProps;
  }
};

export { updateSiteProps, SiteProps };
