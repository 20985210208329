import { getAnalytics } from "@dcl/ui";
import loginManager from "../loginManager";
import rehydrators from "../rehydrators";
import campaignTracking from "../services/campaign-tracking";

const gtm = getAnalytics("gtm");
const consoleAnalytics = getAnalytics("console");

const analytics = (...args) => {
  gtm(...args);

  if (window.emcmUiEnableConsole) {
    consoleAnalytics(...args);
  }
};

export default {
  extra: {
    analytics,
    campaignTracking,
    loginManager,
    rehydrators
  }
};
