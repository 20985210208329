import React from "react";
import ReactDOM from "react-dom";
import { AjaxContent, Anchor, Modal, getClassNameFactory } from "@dcl/ui";

import { options } from "./reactFromMarkup";

const getAnchorClassName = getClassNameFactory(Anchor.displayName);

let rehydrationOptions = options;

const setRehydrationOptions = newOptions => {
  rehydrationOptions = newOptions;
};

const clickHandler = event => {
  const { target } = event;
  const anchor = target.closest(`.${getAnchorClassName()}[data-content-modal]`);

  if (anchor) {
    const { contentLocation, modalLabel } = anchor.dataset;

    // The <Modal /> will use a portal. We can do the root render detached from
    // the main DOM; when ReactDOM.render reaches the portal, it'll attach to
    // #modalRoot.
    let el = document.createElement("div");

    const closeThisComponent = () => {
      ReactDOM.unmountComponentAtNode(el);

      // Remove the reference, so it can get garbage collected
      el = null;
    };

    ReactDOM.render(
      <Modal ariaLabel={modalLabel} closeThisComponent={closeThisComponent}>
        <AjaxContent
          failedContent="Failed to retrieve content"
          loadingLabel="Loading"
          location={contentLocation}
          reactFromMarkupRehydrators={rehydrationOptions.extra.rehydrators}
          reactFromMarkupOptions={rehydrationOptions}
        />
      </Modal>,
      el
    );

    event.stopPropagation();
    event.preventDefault();

    return false;
  }
};

const initModals = () => {
  document.querySelector("#root").addEventListener("click", clickHandler);
};

export { initModals, setRehydrationOptions };
