import campaignTracking from "./services/campaign-tracking";
import { options } from "./reactFromMarkup";
import rehydrate from "@tr-digital/react-rehydrate";
import { initModals } from "./modals";
import { firePageLoad } from "./services/satellite-tracking";

const doRehydrate = async () => {
  await rehydrate(document.body, options.extra.rehydrators, options);
};

export default async () => {
  window.ewp = window.ewp || {};
  window.ewp.campaignTracking = campaignTracking;

  // rehydrate on Adobe Target DOM change
  document.addEventListener("at-content-rendering-succeeded", () =>
    doRehydrate()
  );

  await doRehydrate();

  // this polyfill is for lazy loading images in older browsers, and is needed
  // even in newer browsers to allow images in noscript tags to show. This polyfill
  // must be loaded after rehydration completes rather than before.
  (async () => {
    await import("loading-attribute-polyfill");
  })();

  initModals();

  firePageLoad();
};
