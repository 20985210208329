import async from "async";

export const retryOptions = {
  times: 5,
  interval: 500
};

export const checkSatellite = cb => {
  // eslint-disable-next-line
  cb(!window.__satelliteLoaded);
};

export const satelliteCallback = err => {
  if (err) {
    // eslint-disable-next-line
    console.warn("Satellite was not loaded in times: ", retryOptions.times);
  } else {
    // eslint-disable-next-line
    window._satellite.track("subscribePageReady");
    // eslint-disable-next-line
    console.info("Satellite was loaded");
  }
};

export const firePageLoad = () => {
  async.retry(retryOptions, checkSatellite, satelliteCallback);
};
