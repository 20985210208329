const getLocation = href => {
  const location = document.createElement("a");

  location.href = href;
  // IE doesn't populate all link properties when setting .href with a relative URL,
  // however .href will return an absolute URL which then can be used on itself
  // to populate the additional fields.
  if (location.host === "") {
    location.href = location.href; // eslint-disable-line no-self-assign
  }

  return location;
};

export default getLocation;
